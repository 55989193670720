import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import website from '../../config/website'
import { LocaleContext } from '../components/layout/layout'
import LocalizedLink from '../components/LocalizedLink'
import { H1, H1Content, P } from '../styles/common/Typo'
import { Listing, SEO } from '../components'
import { Container, Section } from '../styles/common/Layout'
import tw from 'twin.macro'
import styled from 'styled-components'

const LocaleSwitcherStyle = theme => css`
  [data-name='locale-switcher'] {
    color: #000;
    a {
      color: white;
    }
  }
`

const A = styled(LocalizedLink)`
  ${tw`transition-all font-bold`}
  max-width: max-content;
  display: inline-flex;
  span:after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    background-color: black;
    position: relative;
    bottom: 0px;
    transition: width .5s;
  }
  span:hover::after {
    width: 100%;
  }
`;

const Category = ({
  pageContext: { category, categoryId, locale },
  data: {
    posts: { edges, totalCount },
  },
  location,
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <SEO
        title={`${i18n.category}: ${category} | Blog | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
      />
      <Section id="section__category__overview" SmallPadding>
        <Container>
          <H1>{i18n.category}</H1>
          <H1Content>{category}</H1Content>
          <P>
            {totalCount} {totalCount === 1 ? 'Post' : 'Posts'} {totalCount === 1 ? i18n.was : i18n.were} {i18n.tagged} "
        {category}"
          </P>
          <P>
            <A to="/categories"><span>{i18n.allCategories}</span></A>
          </P>

        </Container>
      </Section>
      <Section id="section__category__posts" Color="White" SmallPadding>
        <Container>
          <Listing posts={edges} />
        </Container>
      </Section>

    </>
  )
}
export default Category
Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    posts: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
export const pageQuery = graphql`
  query CategoryPage($categoryId: ID!, $locale: String!) {
    posts: allPrismicBlogPost(
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: {
          categories: { elemMatch: { category: { id: { eq: $categoryId } } } }
        }
        lang: { eq: $locale }
      }
    ) {
      totalCount
      edges {
        node {
          uid
          data {
            title {
              text
            }
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`